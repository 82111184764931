import { useState } from "react";

function App() {
  const [Pass, updatePass] = useState();
  const [Key, updateKey] = useState();
  const [Output, setOutput] = useState();

  const handlePassChange = (event) => {
    updatePass(event.target.value);
  };
  const handleKeyChange = (event) => {
    updateKey(event.target.value);
  };

  const send = async () => {
    try {
      fetch("http://localhost:4000/create-key", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ pass: Pass, key: Key }),
      })
        .then((response) => response.json())
        .then((result) => {
          console.log(result.result || result.error || result.message);
          setOutput(result.result || result.error || result.message);
        });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="App">
      <input onChange={handlePassChange} value={Pass} type="password"></input>
      <input onChange={handleKeyChange} value={Key}></input>
      <button onClick={send}>send</button>
      <div>Output: {`${Output}`}</div>
    </div>
  );
}

export default App;
